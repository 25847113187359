<template>
  <div>
    <div class="site-section bg-light">
      <h1 class="mb-2" style="text-align: center;">{{$t("pages.about.company.title")}}</h1>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12" data-aos="fade-up" data-aos-delay="100">
            <br />
            <p style="text-align:center; font-size:18px; text-transform: uppercase">
              {{$t("pages.about.company.description")}}
            </p>
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="post-content  col-md-10" align="center">
              <p class="quote">
                <span
                  > {{$t("pages.about.slogan")}}</span
                >
              </p>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.mision.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                {{$t("pages.about.mision.description")}}
              </p>
            </div>

            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.vision.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                {{$t("pages.about.vision.description")}}
              </p>
            </div>
            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.values.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                <ul >
                  <li>
                    {{$t("pages.about.values.example_one.description")}}

                  </li>
                  <li>{{$t("pages.about.values.example_two.description")}}</li>
                </ul>
              </p>
            </div>
          </div>

          <!--
              <div class="col-md-10" data-aos="fade-up" data-aos-delay="100">
                <br>
                <h1 class="mb-2" style="text-align:center; margin-top:1px; padding-botton:2px"> DESA ESTA CONTIGO </h1>
              </div>

              <div class="col-md-10" data-aos="fade-up" data-aos-delay="100">
                <br>
                <p style="text-align:center; padding-bottom:30px; font-size:20px">
                 Soluciones Desa
                </p>
              </div>

              <div class="col-md-3" data-aos="fade-up" data-aos-delay="100">
                <br>
                <h1 class="mb-2" style="text-align: center;font-size:16px"> <i ></i> COMPRA</h1>
              </div>

              <br>

             <div class="row">
              <div class="col-md-6 col-lg-4">
                <a href="#" class="service text-center">
                  <span class="icon flaticon-coin"></span>
                  <h2 class="service-heading">COMPRA</h2>
                </a>
              </div>

              <div class="col-md-6 col-lg-4">
                <a href="#" class="service text-center">
                  <span class="icon flaticon-rent"></span>
                  <h2 class="service-heading">RENTA</h2>
                </a>
              </div>

              <div class="col-md-6 col-lg-4">
                <a href="#" class="service text-center">
                  <span class="icon flaticon-house"></span>
                  <h2 class="service-heading">INVIERTE</h2>
                </a>
              </div>
            </div>
          -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  
  created() {
    window.scrollTo(0, 0);
  },
};
</script>