
<template>
  <select name="" id="locale" v-model="$i18n.locale" >
    <option
      v-for="locale in locales"
      :key="locale.key"
      :value="locale.key"
      :style="`background-image:url(${locale.icon});`"
    >
      {{ locale.label }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      locales: [
        { key: "es", label: "Español" },
        { key: "en", label: "English" },
      ],
    };
  },
  watch: {
    "$i18n.locale": {
      handler(locale) {
        localStorage.setItem("locale", locale);
      },
    },
  },
};
</script>

<style>
#locale {
  padding:7px 10px;
  background-color: transparent;
  color: #fff;
  border: none;
  font-family:  "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.4285em;
  letter-spacing: .05em;
}
#locale:focus{
  outline: none;
  
}
@media only screen and (max-width:1199px) {
  #locale {
    padding:7px 15px;
    color: #212529;
    line-height: 1.4285em;
    letter-spacing: .0em;
    font-size: 20px; 
  }
}
</style>