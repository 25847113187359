<template>
  <!-- Modal -->
    <div class="modal fade" ref="modalUnit" id="modalUnit" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="modalUnitLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div v-if="infoUnit" class="modal-content">
                <!--Modal Header-->
                <div class="modal-header">
                    <h5 class="modal-title text-center" id="modalUnitLabel">{{infoUnit.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <!--Modal Body-->
                <div class="modal-body container">
                    <!-- Alert section -->
                    <main id="alertContent">
                        <div class="property-topinfo cc_cursor" style="padding-top:20px;">
                        <div class="row justify-content-center" style="padding: 5px;font-size:16px">  
                            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b > Folio: </b>
                                <br>
                                <span>{{infoUnit.folio}}</span>
                            </div>
                            <div v-if="infoUnit.m2c!=0" style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b > {{$t("pages.development_detail.mapper_alert_form_area")}}: </b>
                                <br>
                                <span>{{infoUnit.m2c}} m<sup>2</sup></span>
                            </div>
                            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b > {{$t("pages.development_detail.mapper_alert_form_cost")}}: </b>
                                <br>
                                <span>{{(termSelected==1)?infoUnit.priceS:infoUnit.priceFinance}} </span>
                            </div>
                            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b > {{$t("pages.development_detail.mapper_alert_form_availability")}}: </b>
                                <br>
                                <span>{{infoUnit.status}} </span>
                            </div>                   
                            <div  v-if="termSelected !=1" style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b> {{$t("pages.development_detail.mapper_alert_form_deposit")}}: </b>
                                <br>
                                <span>{{infoUnit.inicial}}</span>
                            </div>
                            
                            <div v-if="termSelected !=1" style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b > {{$t("pages.development_detail.mapper_alert_form_monthlypayment")}}: </b>
                                <br>
                                <span>{{infoUnit.termPrice[`${termSelected}`]}} </span>
                            </div>
                            <div style="margin: 4px 0; color: black"  class="col-md-4 item-details">
                                <b > {{$t("pages.development_detail.mapper_alert_form_term")}}: </b>
                                <br>
                                <select class="form-control" id="term" name="term" data-placeholder="Plazo" v-model="termSelected">
                                    <option v-for="(term,i) in infoUnit.term" :key="term" :index="i" :value="i">{{infoUnit.term[i]}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="container-fluid mt-5">
                            <h3>{{$t("pages.development_detail.mapper_alert_contactform_title")}}</h3>
                            <hr>
                            <div class="row justify-content-center">
                            <div class="col-12">
                                <ContactForm />
                            </div>
                            </div>
                        </div>
                        
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ContactForm from "@/components/layout/ContactForm.vue";
export default {
    components: {
        ContactForm,
    },
    computed: {
        ...mapGetters({
            infoUnit: "_getActiveUnit"
        })
    },
    data(){
        return {      
            termSelected:"1",
        }
    },
}
</script>
<style>
@media (min-width: 576px){
    .modal-dialog {
        max-width: max-content;
        margin: 1.75rem auto;
    }
}
</style>